import { useNostoApi } from './useNostoApi';

/**
 * Sends home page viewed action to the Nosto.
 */
export function useNostoHome() {
  useNostoApi((api) => {
    // eslint-disable-next-line prettier/prettier
    return api.defaultSession()
              .viewFrontPage()
              .setPlacements(api.placements.getPlacements())
              .load();
  }, 'HOME');
}
